// Gatsby browser code
import { AnimatePresence } from 'framer-motion';
import { GatsbyBrowser } from 'gatsby';
import React from 'react';

import './src/theme/theme.scss';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
  <AnimatePresence>{element}</AnimatePresence>
);
